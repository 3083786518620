import React, { useState } from "react";
import InputContainer from "../../../../utils/inputContainer";
import { useTranslation } from "react-i18next";
import infoIconEmpty from "../../../../../assets/media/infoIconEmpty.svg";
import loyaltyBadge from "../../../../../assets/media/BsAwardFill.svg";
import { LoyaltyLevelInput } from "./LoyaltyLevelInput";

const LoyaltyLevelForm = () => {
  const { t } = useTranslation("brandAdminProfile");

  // Using a single state object for targets
  const [target1, setTarget1] = useState();
  const [target2, setTarget2] = useState();
  const [target3, setTarget3] = useState();

  const LoyaltyLevelBadge = ({ badgeCount }) => {
    return (
      <div className="loyalty-level-badge">
        <div className="loyalty-level-badge-count">{badgeCount}</div>
        <img className="loyalty-level-badge-icon" src={loyaltyBadge} />
      </div>
    );
  };

  const LoyaltyLevelColumn = ({ children, type }) => {
    const childrenArray = React.Children.toArray(children);

    return (
      <div className="loyalty-level-column">
        {type === "input"
          ? childrenArray.map((child, index) => (
              <React.Fragment key={index}>
                {index === 0 && (
                  <div className="loyalty-level-column-part-long loyalty-level-column-part" />
                )}

                <div className="loyalty-level-column-part-short loyalty-level-column-part">
                  {child}
                </div>
                {index === childrenArray.length ? null : (
                  <div className="loyalty-level-column-part-long loyalty-level-column-part" />
                )}
              </React.Fragment>
            ))
          : childrenArray.map((child, index) => (
              <React.Fragment key={index}>
                <div className="loyalty-level-column-part-long loyalty-level-column-part">
                  {child}
                </div>
                {index < childrenArray.length - 1 && (
                  <div
                    className={`loyalty-level-column-part-short loyalty-level-column-part ${
                      type === "line"
                        ? "loyalty-level-column-part-short-color"
                        : ""
                    }`}
                  />
                )}
              </React.Fragment>
            ))}
      </div>
    );
  };

  const LoyaltyLevelGradient = () => {
    return (
      <>
        <LoyaltyLevelColumn>
          <LoyaltyLevelBadge badgeCount="IV" />
          <LoyaltyLevelBadge badgeCount="III" />
          <LoyaltyLevelBadge badgeCount="II" />
          <LoyaltyLevelBadge badgeCount="I" />
        </LoyaltyLevelColumn>
        <LoyaltyLevelColumn type="line">
          <div className="loyalty-level-gradient-part-long loyalty-level-gradient-part-1" />
          <div className="loyalty-level-gradient-part-long loyalty-level-gradient-part-2" />
          <div className="loyalty-level-gradient-part-long loyalty-level-gradient-part-3" />
          <div className="loyalty-level-gradient-part-long loyalty-level-gradient-part-4" />
        </LoyaltyLevelColumn>
      </>
    );
  };

  return (
    <InputContainer
      title={t("sotierLevels")}
      isMandatory={true}
      titleDescription={t("sotierLevelsDescription")}
    >
      <div className="loyalty-level-info-container">
        <img
          className="loyalty-level-info-icon"
          src={infoIconEmpty}
          alt="info:"
        />
        <div className="loyalty-level-info">
          12 Ay Süresince <br />
          <br />
          *Alışveriş Bitmesine İstinaden 24 Saat Sonra Güncellenecektir.
          <br />
          <br />
          *1 Sene Süresince Seviye Korunacaktır. Hiç Alışveriş yapmadıkları
          taktirde Sotierlerınızın seviyesi: Seviye 1’e düşecektir.
        </div>
      </div>

      <div className="loyalty-level-form">
        <div className="loyalty-level-form-part loyalty-level-form-part-left">
          <LoyaltyLevelGradient />
          <LoyaltyLevelColumn type="input">
            <LoyaltyLevelInput
              value={target3}
              onChange={(e) => setTarget3(e.target.value)} // Update target3 state
            />
            <LoyaltyLevelInput
              value={target2}
              onChange={(e) => setTarget2(e.target.value)} // Update target2 state
            />
            <LoyaltyLevelInput
              value={target1}
              onChange={(e) => setTarget1(e.target.value)} // Update target1 state
            />
          </LoyaltyLevelColumn>
        </div>
        <div className="loyalty-level-form-part">
          <LoyaltyLevelInput adornment={true} title={"Seviye 4"} value={4} />
          <LoyaltyLevelInput adornment={true} title={"Seviye 3"} value={3} />
          <LoyaltyLevelInput adornment={true} title={"Seviye 2"} value={2} />
          <LoyaltyLevelInput adornment={true} title={"Seviye 1"} value={1} />
        </div>
      </div>
    </InputContainer>
  );
};

export default LoyaltyLevelForm;
