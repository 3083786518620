import React from "react";

const PersonalInfo = ({
  isFormFilled,
  checkImage,
  firstName,
  lastName,
  phoneNumber,
  email,
  handleChange,
  tick,
  t,
}) => {
  return (
    <div className="brand-admin-info-container">
      <div className="brand-admin-info-container-title-container">
        {isFormFilled && (
          <img
            src={checkImage}
            alt="ico"
            className="brand-admin-info-container-title-img"
          />
        )}
        <div className="brand-admin-info-container-title">
          {t("personalInfo")}
        </div>
      </div>
      <div className="brand-admin-info-container-wrapper">
        <div className="brand-admin-info-container-row">
          {/* First Name */}
          <div className="brand-admin-input-container">
            <div className="brand-admin-input-title">{t("name")}:</div>
            <div className="brand-admin-input-area">
              <input
                id="firstName"
                className="brand-admin-input"
                type="text"
                value={firstName}
                onChange={handleChange}
                readOnly={true}
              />
              <img
                src={tick}
                alt="ico"
                className={`brand-admin-input-img ${
                  firstName !== "" ? "slide-in" : ""
                }`}
              />
            </div>
          </div>

          {/* Last Name */}
          <div className="brand-admin-input-container">
            <div className="brand-admin-input-title">{t("surname")}:</div>
            <div className="brand-admin-input-area">
              <input
                id="lastName"
                className="brand-admin-input"
                type="text"
                value={lastName}
                onChange={handleChange}
                readOnly={true}
              />
              <img
                src={tick}
                alt="ico"
                className={`brand-admin-input-img ${
                  lastName !== "" ? "slide-in" : ""
                }`}
              />
            </div>
          </div>
        </div>

        <div className="brand-admin-info-container-row">
          {/* Phone Number */}
          <div className="brand-admin-input-container">
            <div className="brand-admin-input-title">{t("phoneNumber")}:</div>
            <div className="brand-admin-input-area">
              <input
                id="phoneNumber"
                className="brand-admin-input"
                type="text"
                value={phoneNumber}
                onChange={handleChange}
                readOnly={true}
              />
              <img
                src={tick}
                alt="ico"
                className={`brand-admin-input-img ${
                  phoneNumber !== "" ? "slide-in" : ""
                }`}
              />
            </div>
          </div>

          {/* Email */}
          <div className="brand-admin-input-container">
            <div className="brand-admin-input-title">{t("email")}:</div>
            <div className="brand-admin-input-area">
              <input
                id="email"
                className="brand-admin-input"
                type="text"
                value={email}
                onChange={handleChange}
                readOnly={true}
              />
              <img
                src={tick}
                alt="ico"
                className={`brand-admin-input-img ${
                  email !== "" ? "slide-in" : ""
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
