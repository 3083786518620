let apiUrl,
  authUrl,
  imagesUrl,
  metabaseUrl,
  authenticationGrantType,
  adminClientId,
  adminClientSecret,
  brandClientId,
  brandClientSecret,
  brandTiktokClientKey,
  brandTiktokRedirectUri;

apiUrl = "https://api.qa.soty.io";
authUrl = "https://auth.qa.soty.io";
imagesUrl = "https://images.qa.soty.io";
metabaseUrl = "https://metabase.qa.soty.io";
authenticationGrantType = "password";
adminClientId = "admin-client";
adminClientSecret = "Qkjuo2yPSI1c5C631wa9mWdO5cqUdF8p";
brandClientId = "brand-client";
brandClientSecret = "WWM6AhMUIUUyqOSET6jXAOSDGxGUq01W";
brandTiktokClientKey = "sbaw5pl25vvg8nveg7";
brandTiktokRedirectUri = "/brand/settings/profile";

const loadConfig = async () => {
  console.log(process.env);
  const response = await fetch("/config.json");
  if (response.ok) {
    const data = await response.json();
    apiUrl = data.apiUrl || apiUrl;
    authUrl = data.authUrl || authUrl;
    imagesUrl = data.imagesUrl || imagesUrl;
    metabaseUrl = data.metabaseUrl || metabaseUrl;
    authenticationGrantType =
      data.authenticationGrantType || authenticationGrantType;
    adminClientId = data.adminClientId || adminClientId;
    adminClientSecret = data.adminClientSecret || adminClientSecret;
    brandClientId = data.brandClientId || brandClientId;
    brandClientSecret = data.brandClientSecret || brandClientSecret;
  }
};
export {
  apiUrl,
  authUrl,
  imagesUrl,
  metabaseUrl,
  authenticationGrantType,
  adminClientId,
  adminClientSecret,
  brandClientId,
  brandClientSecret,
  brandTiktokClientKey,
  brandTiktokRedirectUri,
  loadConfig,
};
