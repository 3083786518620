import React, { useState, useEffect, useRef } from "react";

export const LoyaltyLevelInput = ({
  title,
  adornment,
  value = "",
  onChange,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Refocus the input if value changes
    }
  }, [value]);

  return (
    <div className="loyalty-level-input-container">
      {title && <div className="loyalty-level-input-title">{title}* </div>}

      <div className="loyalty-level-input-wrapper">
        {adornment && (
          <span className="loyalty-level-input-start-adornment">%</span>
        )}
        <input
          ref={inputRef} // Ref attached to input
          type="number"
          value={value} // Value is directly controlled from parent component
          onChange={onChange} // Handle change from parent (no need to access e.target.value here)
          className="loyalty-level-input"
        />
      </div>
    </div>
  );
};
